.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #08213f;
  color: #dbdbdb;
  padding: 5px 30px;
  font-family: 'popins',sans-serif;


}

.headerName {
  font-size: 30px;
  color: #dbbd6b;
}

.logoMain {
  height: 50px;
  width: 50px;

}

.toggleButton {
  display: none;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}

.headerTabs {
  display: flex;
  gap: 20px;
  color: #dbdbdb;
  font-weight: 700;
}




@media (max-width: 768px) {
  .toggleButton {
    display: block;
  }

  .headerTabs {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    background-color: #dbdbdb;
    z-index: 1;
    padding: 10px;
    border-bottom: 1px solid #08213f9e;
  }

  .showTabs {
    display: flex;
  }
}