.homepage_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    color: #08213f;

}

.workingImage {
    height: 200px;
    width: 200px;
    padding: 20px;
}

.messageText {
    font-size: 50px;
    font-family: 'popins', sans-serif;
    font-weight: 500;
}

.subMessageText {
    font-size: 20px;
    font-family: 'popins', sans-serif;
    font-weight: 100;
    color: #08213f;

}
.socialMediaContainer{
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid #08213f;

}
