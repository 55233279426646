
.footer {
    background-color: #08213f;
    color: #dbbd6b;
    padding: 1px 0;
    font-size: 12px;
    text-align: center;
    font-family: 'popins',sans-serif;

  }
